.modal-wrapper {
  position: fixed;
  top: min(30%, 100px);
  right: min(40%, 50px);
  bottom: min(40%, 50px);
  left: min(40%, 50px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none; 
}

.modal-content {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100; /* モーダルをオーバーレイの上に表示するため */
}

.modal-overlay .modal-content {
  pointer-events: auto;
}
